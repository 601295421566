@import url(https://fonts.googleapis.com/css?family=Quicksand:400,500,700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media print {
  .pagebreak {
    page-break-before: always;
  }
}

